(function () {
  'use strict';

  angular
  .module('neo.home.persons')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.persons', {
      url: '/persons',
      templateUrl: 'home/persons/persons.tpl.html',
      controller: 'PersonsCtrl',
      controllerAs: 'vm',
      params: {
        selectedEntity: undefined,
        selectedTeam: undefined
      },
      resolve: {
        entities: function (Entitats) {
          return Entitats.query().$promise;
        },
        categories: function (Categories) {
          return Categories.query().$promise;
        },
        sports: function (Sports) {
          return Sports.query().$promise;
        },
        licenseTypes: function (LicenseTypes) {
          return LicenseTypes.query().$promise;
        },
        genders: function (Genders) {
          return Genders.query().$promise;
        }
      }
    });
  }
}());
